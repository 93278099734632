import React from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { getDefectsByStatus } from "services/analytics";
import useChartColors from '../ReportChart/hooks/useChartColors';

const DefectsStatusReport = () => {
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  const { getAllColors } = useChartColors();

  const chartConfig = {
    id: 'defects-by-status',
    title: `${customCaptions?.defectPlural} by Status`,
    fetchData: async (params) => {
      return await getDefectsByStatus(params);
    },
    allowedChartTypes: ['pie', 'tree-map', 'vertical-bar'],
    defaultChartType: 'tree-map',
    series: [
      { 
        label: 'Count', 
        key: 'count', 
        showLegend: false,
        colors: getAllColors()
      }
    ],
    transformData: (response) => {
      return response?.data?.data;
    },
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: true,
      riskRatings: true
    },
    viewData: '/app/defects',
    drilldown: {
      defaultChartType: 'vertical-bar',
      allowedChartTypes: ['vertical-bar', 'horizontal-bar'],
      series: [
        { 
          label: 'Count', 
          key: 'count', 
          showLegend: false,
          colors: getAllColors()
        }
      ],
      currentDrilldownLabel: `${customCaptions?.asset || 'Asset'}`,
      getParams: (dataPoint) => {
        return {
          defectStatusId: dataPoint.id,
          // LEGACY HANDLING: Add the legacy status structure for backward compatibility
          defectStatus: {
            id: dataPoint.id,
            name: dataPoint.name || "Show All"
          }
        };
      }
    }
  };

  return (
    <ReportPage 
      title={`${customCaptions?.defectPlural} by Status`}
      chartConfig={chartConfig}
    />
  );
};

export default DefectsStatusReport; 