import { useState, useCallback, useEffect, useMemo } from 'react';
import { startOfMonth, endOfMonth } from 'date-fns';

export const useDrilldown = (initialConfig) => {
  const [drilldownConfig, setDrilldownConfig] = useState(null);
  const [drilldownParams, setDrilldownParams] = useState({});
  const [drilldownHistory, setDrilldownHistory] = useState([]);
  const [drilldownData, setDrilldownData] = useState(null);
  const [seriesCache, setSeriesCache] = useState(null);
  const [navigationPath, setNavigationPath] = useState([]);
  const [selectedChartType, setSelectedChartType] = useState(null);

  useEffect(() => {
    if (typeof initialConfig?.series === 'function') {
      const loadSeries = async () => {
        try {
          const result = await initialConfig.series();
          if (Array.isArray(result)) {
            setSeriesCache(result);
          }
        } catch (error) {
          console.error('Error loading series:', error);
        }
      };
      
      loadSeries();
    } else if (Array.isArray(initialConfig?.series)) {
      setSeriesCache(initialConfig.series);
    }
  }, [initialConfig]);

  const resolveLabel = useCallback((labelValue, dataPoint = null, fallback = '') => {
    if (typeof labelValue === 'function') {
      return labelValue(dataPoint);
    }
    return labelValue || fallback;
  }, []);

  const resolveDrilldownItemLabel = useCallback((config, dataPoint) => {
    if (config?.drilldownLabel) {
      return resolveLabel(config.drilldownLabel, dataPoint);
    }
    
    return dataPoint?.label || dataPoint?.name || 'Item';
  }, [resolveLabel]);

  useEffect(() => {
    let path = [];
    
    const activeConfig = drilldownConfig || initialConfig;
    
    drilldownHistory.forEach((historyItem, index) => {
      path.push({
        label: resolveDrilldownItemLabel(historyItem.config, historyItem.dataPoint),
        level: index
      });
    });
    
    path.push({
      label: resolveLabel(activeConfig?.currentDrilldownLabel, drilldownData, activeConfig?.title || 'Overview')
    });
    
    setNavigationPath(path);
  }, [drilldownConfig, drilldownHistory, initialConfig, resolveLabel, drilldownData, resolveDrilldownItemLabel]);

  const resetDrilldown = useCallback(() => {
    setDrilldownConfig(null);
    setDrilldownParams({});
    setDrilldownHistory([]);
    setDrilldownData(null);
    setSelectedChartType(null);
  }, []);

  const navigateToLevel = useCallback((level) => {
    if (level === 0) {
      resetDrilldown();
      return;
    }
    
    if (level < 0 || level >= drilldownHistory.length) return;
    
    const newHistory = drilldownHistory.slice(0, level);
    const lastHistoryItem = newHistory[newHistory.length - 1];
    
    setDrilldownHistory(newHistory);
    setDrilldownConfig(lastHistoryItem.config.drilldown);
    setDrilldownParams(lastHistoryItem.params);
  }, [drilldownHistory, resetDrilldown]);

  const handleDrilldown = useCallback((dataPoint) => {
    const currentConfig = drilldownConfig || initialConfig;
    
    if (!currentConfig?.drilldown) return;
    
    setDrilldownData(dataPoint);

    const additionalParams = {};
    
    if (dataPoint.year && dataPoint.month) {
      try {
        const monthDate = new Date(dataPoint.year, dataPoint.month - 1);
        const startDate = startOfMonth(monthDate);
        const endDate = endOfMonth(monthDate);
        
        additionalParams.startDate = startDate.toISOString();
        additionalParams.endDate = endDate.toISOString();
      } catch (error) {
        console.error('Error setting date range for drilldown:', error);
      }
    }
    
    if (currentConfig?.drilldown?.getParams) {
      const customParams = currentConfig.drilldown.getParams(dataPoint, drilldownParams);
      Object.assign(additionalParams, customParams);
    }

    const newParams = {
      ...drilldownParams,
      ...additionalParams
    };
    
    const newHistory = [...drilldownHistory, { 
      config: currentConfig,
      params: newParams,
      dataPoint
    }];
    
    setDrilldownHistory(newHistory);
    setDrilldownParams(newParams);
    setDrilldownConfig(currentConfig.drilldown);
  }, [initialConfig, drilldownConfig, drilldownParams, drilldownHistory]);

  // Create a merged config that uses initialConfig as base and overlays drilldown config
  const activeConfig = useMemo(() => {
    if (!drilldownConfig) return initialConfig;
    
    // Create a deep merged config
    // Start with all properties from initialConfig
    const mergedConfig = { ...initialConfig };
    
    // Then overlay all properties from drilldownConfig
    Object.keys(drilldownConfig).forEach(key => {
      mergedConfig[key] = drilldownConfig[key];
    });

    // If selected chart type isn't allowed in current config, fall back to default
    if (selectedChartType && mergedConfig.allowedChartTypes && 
        !mergedConfig.allowedChartTypes.includes(selectedChartType)) {
      setSelectedChartType(mergedConfig.defaultChartType);
    }
    
    return mergedConfig;
  }, [drilldownConfig, initialConfig, selectedChartType]);

  return {
    config: activeConfig,
    originalConfig: initialConfig,
    drilldownParams,
    isDrilledDown: !!drilldownConfig,
    drilldownHistory,
    navigationPath,
    handleDrilldown,
    navigateToLevel,
    resetDrilldown,
    drilldownData,
    seriesCache,
    selectedChartType,
    setSelectedChartType
  };
};
