import React, { useEffect, useRef } from 'react';
import { Paper, Box, useTheme } from '@mui/material';
import { ChartTypeSelector } from './ChartTypeSelector';
import { ChartFactory } from '../Charts/ChartFactory';
import { ChartTitle } from './ChartTitle';
import { useReport } from '../ReportContext';
import { DrilldownNavigation } from './DrilldownNavigation';
import { NoDataMessage } from './NoDataMessage';
import { InvalidFiltersMessage } from './InvalidFiltersMessage';
import { ViewDataButton } from './ViewDataButton';
import { ExportButton } from './ExportButton';

export const ChartContainer = ({ 
  title, 
  subtitle, 
  data,
  isLoading,
  isDrilledDown,
  invalidFiltersMessage,
  LoadingComponent 
}) => {
  const theme = useTheme();
  const { config, selectedChartType, setSelectedChartType } = useReport();
  
  // Create refs for chart export
  const chartRef = useRef(null);
  const containerRef = useRef(null);

  // Initialize chart type if not set
  useEffect(() => {
    if (!selectedChartType && config.defaultChartType) {
      setSelectedChartType(config.defaultChartType);
    }
  }, [config.defaultChartType, selectedChartType, setSelectedChartType]);

  // Check if data exists and has content
  const hasData = data && (
    // Handle flat array data format
    (Array.isArray(data) && data.length > 0) ||
    // Handle object-of-arrays data format
    (typeof data === 'object' && !Array.isArray(data) && 
     Object.values(data).length > 0 && 
     Object.values(data).every(d => Array.isArray(d) && d.length > 0))
  );

  return (
    <Paper 
      elevation={0}
      ref={containerRef}
      sx={{ 
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        padding: '24px 32px 32px 32px',
        position: 'relative',
        marginTop: '16px',
        overflow: 'visible',
        backgroundColor: '#ffffff'
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '32px',
        position: 'relative'
      }}>
        <ChartTitle
          title={title}
          subTitle={subtitle}
          isDrilledDown={isDrilledDown}
        />
        {!invalidFiltersMessage && hasData && (
          <Box sx={{ marginTop: '8px' }}>
            <DrilldownNavigation />
          </Box>
        )}
        {hasData && (
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              position: 'absolute',
              top: 0,
              right: 0
            }}
            data-html2canvas-ignore="true"
          >
            <ViewDataButton 
              data={data} 
              config={config} 
            />
            <ChartTypeSelector 
              allowedTypes={config.allowedChartTypes}
              currentType={selectedChartType}
              onTypeChange={setSelectedChartType}
            />
            <ExportButton 
              title={title}
              chartRef={chartRef}
              containerRef={containerRef}
            />
          </Box>
        )}
      </Box>

      <Box 
        sx={{ 
          '& .recharts-wrapper': {
            margin: '0 auto',
            maxWidth: '100%'
          },
          minHeight: '300px',
          padding: '0',
          position: 'relative'
        }}
      >
        {isLoading ? (
          LoadingComponent
        ) : invalidFiltersMessage ? (
          <InvalidFiltersMessage message={invalidFiltersMessage} />
        ) : hasData ? (
          <div ref={chartRef}>
            <ChartFactory 
              type={selectedChartType}
              data={data}
              config={config}
              isDrilledDown={isDrilledDown}
            />
          </div>
        ) : (
          <NoDataMessage />
        )}
      </Box>
    </Paper>
  );
}; 